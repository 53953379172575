import React, { useState, useRef } from "react";
import { FaCircleInfo } from "react-icons/fa6";

import axios from "axios";
import FilesMap from "./FilesMap";
import {
  UploadInput,
  Label,
  P,
  Form,
  MainButton,
  SubmitButton,
  Container,
} from "../../../styles/individualComponents/add_form";
import useCustomModal from "../../../hooks/useCustomModal";
import { ModalError } from "./ModalError";
import { ModalInfo } from "./ModalInfo";
import toast, { Toaster } from "react-hot-toast";
import Select from "../../reactComponents/select/Select";

const Ahorro = ({env_color, allows,translations}) => {
  const [file, setfile] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedInvoiceType, setSelectedInvoiceType] = useState("6");  // Estado para el tipo de factura
  const fileInputRef = useRef(null);
  const [errorInfo, setErrorInfo] = useState(null);
  const handleAttachClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const ErrorModal = useCustomModal();
  const InfoModal = useCustomModal();

function postData(data) {
  // Apply the overlay
  const overlay = document.createElement('div');
  overlay.style.position = 'fixed';
  overlay.style.top = '0';
  overlay.style.left = '0';
  overlay.style.width = '100%';
  overlay.style.height = '100%';
  overlay.style.background = 'rgba(0, 0, 0, 0.5)'; // Adjust the transparency as needed
  overlay.style.zIndex = '1031';
  document.body.appendChild(overlay);

  const loadingToastId = toast.loading("Procesando factura...", {
    style: {
      background: env_color,
      color: '#fff',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      fontWeight: 'bold',
      marginTop: '40vh',
      zIndex: '10032',
    },
  });

  axios
    .post("/api/v2/parse-energy-invoice", data)
    .then((res) => {
      let id = res.data.idRegistro ? res.data.idRegistro : res.data.registerId
      window.location.href = `/energy_invoices/${id}`;
    })
    .catch((error) => {
      document.body.removeChild(overlay);
      toast.dismiss(loadingToastId);
      ErrorModal.openModal();
      setErrorInfo(error);
      setfile([]);
    });
}


  const handleSubmit = (e) => {
    const invoiceTypeToSend = invoiceTypes.find((type) => type.id === selectedInvoiceType)?.type || '';

    e.preventDefault();
    if (file.length > 5) {
      alert(translations.alerts.too_many_files);
    } else if (file.length == 0) {
      //window.location.href = `/energy_invoices/new/electric_2x_invoice`;
      alert(translations.alerts.no_files);
    } else {
      let form = new FormData();
      let fileCounter = 1;
      file.forEach((element) => {
        form.append(`subidaFichero${fileCounter}`, element, element.name);
        fileCounter++;
      });
      form.append("extractContractInfo", "no");
      form.append("extractSavings", "no");
      form.append("contactPerson", "null");
      form.append("phoneNumber", "null");

      if(invoiceTypeToSend != ""){
      form.append("invoiceType", invoiceTypeToSend);
      }

      postData(form);
    }
  };

  const saveFile = (e) => {
    const FilesArr = Array.from(e.target.files);
    const filesAdmited = [];
    FilesArr.map((archivo) => {
      if (archivo.size > 5000000) {
        alert("El tamaño del archivo no puede ser mayor a 5MB");
        return;
      }
      if (
        archivo.type !== "application/pdf" &&
        archivo.type !== "image/png" &&
        archivo.type !== "image/jpeg" &&
        archivo.type !== "image/webp"
      ) {
        alert("El archivo debe ser un PDF o una imagen");
        return;
      }
      filesAdmited.push(archivo);
    });
    if (filesAdmited.length != 0) {
      setfile([...file, ...filesAdmited]);
    }
    e.target.value = null;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    setIsDragging(false);
  };

  const handleManualDataEntry = () => {
    const selectedInvoice = invoiceTypes.find(
      (type) => type.id === selectedInvoiceType
    );
    if (!selectedInvoice || selectedInvoice.type === "") {
      alert("Por favor, selecciona un tipo de factura válido.");
      return;
    }

    const routes = {
      electric: "/energy_invoices/new/electric_2x_invoice",
      gas: "/energy_invoices/new/gas_invoice",
      dual: "/energy_invoices/new/dual_invoice",
      pyme: "/energy_invoices/new/electric_pyme_invoice",
    };
    const route = routes[selectedInvoice.type];
    window.location.href = route;

  };

  //Tipologia permitida por organización
  const invoiceTypes = [
    { id: '1', name: translations.invoice_types.electric_2x, allow: allows.allow_2x, type: 'electric' },
    { id: '2', name: translations.invoice_types.gas, allow: allows.allow_gas, type: 'gas' },
    { id: '3', name: translations.invoice_types.dual, allow: allows.allow_dual, type: 'dual' },
    { id: '4', name: translations.invoice_types.electric_3x, allow: allows.allow_3x, type: 'pyme' },
    { id: '5', name: translations.invoice_types.electric_6x, allow: allows.allow_6x, type: 'pyme' },
    { id: '6', name: translations.invoice_types.auto_detection, allow: true, type: '' }
  ].filter(type => type.allow);

  return (
    <div>
      <Container>
        <Toaster />
        {ErrorModal.showModal ? (
          <ModalError errorInfo={errorInfo} closeModal={ErrorModal.closeModal} translations={translations} />
        ) : null}
        {InfoModal.showModal ? (
          <ModalInfo closeModal={InfoModal.closeModal} translations={translations}/>
        ) : null}
        <Form
          env_color={env_color}
          isDragging={isDragging}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onSubmit={(e) => handleSubmit(e)}

        >
          <P env_color={env_color}>
          {translations.step_1}: {translations.description_1}{" "}
            <FaCircleInfo
              color={env_color}
              size={26}
              cursor="pointer"
              onClick={(e) => InfoModal.openModal()}
              style={{ marginBottom: "5px", marginLeft: "10px" }}
            />
          </P>
          <MainButton env_color={env_color} onClick={handleAttachClick} type="button">
          {translations.upload_invoice}
          </MainButton>
          <FilesMap setfile={setfile} files={file} />
          <SubmitButton type="submit">{translations.process}</SubmitButton>
          <Label>
            <UploadInput
              ref={fileInputRef}
              accept=".pdf, .jpg, .jpeg, .png, .webp"
              onChange={(e) => saveFile(e)}
              type="file"
              multiple
            />
          </Label>
        </Form>
      </Container>
      <div style={{ width: "50%", marginTop: "20px", marginLeft: "auto", marginRight: "auto",textAlign: "center" }}>
        <Select
            data={invoiceTypes} // Datos que pasan las opciones
            dataSelected={selectedInvoiceType} // Valor seleccionado
            selectId="invoice-type-select"
            selectName="invoiceType"
            name={translations.invoice_types.title}
            placeholder={translations.invoice_types.auto_detection}
            reactSetter={(newSelectedValue) => {
              const selectedValue = [...newSelectedValue][0];
              setSelectedInvoiceType(selectedValue); // Actualizar el estado con el tipo de factura
            }}
          />
        {allows.allow_manual_saving_calculations && (
          <MainButton env_color={env_color} style={{ marginTop: "20px"}} onClick={handleManualDataEntry}>
            {translations.manual_data_entry}
          </MainButton>
        )}
      </div>
    </div>
  );
};

export default Ahorro;
